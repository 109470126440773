<template>
  <div class="partner">
    <div class="partnerBox">
      <u-animate-container >
        <u-animate name="zoomIn" delay="0s" duration="0.5s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
          <div class="titleBox" @click.stop="partnersPlay">
            <p class="title1">合作伙伴</p>
            <span class="title1-line"></span>
            <p class="title2">Partners</p>
          </div>
        </u-animate>
      </u-animate-container>

      <video-carousel :videoList="videoList"></video-carousel>
    </div>
  </div>
</template>

<script>
import videoCarousel from "@/components/video-carousel"
import { partnerVideoList } from "@/assets/js/fixed_data"

export default {
  name: "partner",
  components: {
    videoCarousel
  },
  data() {
    return {
      videoList: partnerVideoList
    }
  }
}
</script>

<style lang="less" scoped>
.partner{
  background: #F8F8FF;
  padding-bottom: 1rem;
}
.partnerBox{
  width: 12rem;
  margin:  0 auto;
}
.titleBox{
  text-align: center;
  padding: .5rem 0 .43rem 0;
  font-size: 0;
  color:#333333;
  margin: 0 auto;
  &:hover{
    cursor: pointer;
  }
  .title1{
    text-align: center;
    font-size:.32rem;
    font-weight:500;
  }
  .title1-line{
    display: block;
    margin: 0 auto;
    width: 70px;
    height: 3px;
    background-color: #5b5af3;
    border-radius: 20px;
    transform:rotateY(90deg);
    transition: all 1s;
  }
  &:hover .title1-line{
    transform:rotateY(0deg);
  }
  .title2{
    font-size: 21px;
    font-family: Impact, Charcoal, sans-serif;
    font-weight: normal;
    color: #7776ff33;
    line-height: 31px;
    letter-spacing: 3px;
    font-style: italic;
    margin-top: 0.06rem;
    display: inline-block;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .partnerBox{
    width: calc(100% - .24rem);
  }
  .titleBox{
    padding: 0.3rem 0 0.1rem;
    .title1{
      font-size:20px;
    }
    .title2{
      margin-top:0;
      font-size: 14px;
      letter-spacing: 2px;
    }
  }
}
</style>
