var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-carousel',{staticClass:"videoBox",attrs:{"indicator-position":_vm.direction.indicatorPosition,"interval":4000,"type":_vm.direction.type,"autoplay":false,"arrow":"hover"},on:{"change":_vm.carouselChange}},_vm._l((_vm.videoList),function(item,i){return _c('el-carousel-item',{key:i+'video'},[_c('video-player',{ref:"videoPlayer",refInFor:true,staticClass:"video-player vjs-custom-skin video-self",staticStyle:{"object-fit":"fill"},attrs:{"playsinline":true,"webkit-playsinline":"true","x5-playsinline":"true","x-webkit-airplay":"true","x5-video-player-type":"h5","x5-video-player-fullscreen":"true","x5-video-ignore-metadata":"true","options":{
           autoplay: false,
           muted: false,
           loop: false,
           preload: 'auto',
           language: 'zh-CN',
           aspectRatio: '16:9',
           fluid: true,
           hls: true,
           notSupportedMessage: '此视频暂无法播放，请稍后再试',
           controlBar: {
              timeDivider: false,
              durationDisplay: false,
              remainingTimeDisplay: false,
              fullscreenToggle: false // 全屏按钮
            },
           sources: [
              {
                type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                src: item.url // url地址
              }
          ]
        }}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }