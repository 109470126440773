<template>
  <div>
    <el-carousel class="videoBox" :indicator-position="direction.indicatorPosition" :interval="4000" :type="direction.type" :autoplay="false" arrow="hover" @change="carouselChange">
      <el-carousel-item v-for="(item,i) in videoList" :key="i+'video'">
        <video-player ref="videoPlayer" class="video-player vjs-custom-skin video-self" :playsinline="true" style="object-fit:fill" webkit-playsinline='true' x5-playsinline="true" x-webkit-airplay="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true" :options="{
             autoplay: false,
             muted: false,
             loop: false,
             preload: 'auto',
             language: 'zh-CN',
             aspectRatio: '16:9',
             fluid: true,
             hls: true,
             notSupportedMessage: '此视频暂无法播放，请稍后再试',
             controlBar: {
                timeDivider: false,
                durationDisplay: false,
                remainingTimeDisplay: false,
                fullscreenToggle: false // 全屏按钮
              },
             sources: [
                {
                  type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                  src: item.url // url地址
                }
            ]
          }"/>

      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "video-carousel",
  props:{
    videoList: Array
  },
  computed: {
    ...mapGetters(["isMobile",'clientWidth']),
    player() {
      return this.$refs.videoPlayer.player
    },
    direction() {
      let _params = {
        type:'card',
        indicatorPosition:"none"
      }
      if(this.isMobile){
        _params.type = ''
        _params.indicatorPosition = "outside"
      }
      return _params
    }
  },
  methods: {
    carouselChange(ind) {
      this.$refs.videoPlayer.forEach((item, index) => {
        if (index !== ind) {
          item.player.pause();
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.videoBox {
  //padding-top: .5rem;
  height: 4rem;
}

/deep/ .el-carousel__container {
  height: 100%;
}

/deep/ .el-carousel__mask {
  background-color: transparent;
}

//.video-self {
//  height: 100%;
//  border-radius: 6px;
//  overflow: hidden;
//
//  .video-js {
//    height: 100%;
//  }
//
//  /deep/ .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3 {
//    height: 100%;
//  }
//}

///deep/ .vjs-big-play-button {
//  font-size: 20px !important;
//  border-radius: 50%;
//  height: 20px;
//  width: 42px;
//}
//
///deep/ .el-carousel__button {
//  height: 16px;
//  background: #21a1d0;
//}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .videoBox {
    padding-top: .3rem;
    width: calc(100% - .4rem);
    margin: 0 auto;
    font-size: 0;
  }

  /deep/ .el-carousel__container {
    height: 94%;
  }
}

</style>
