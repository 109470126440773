<template>
  <div>
    <page-guide :options="options" :slogan="slogan" :pc-image="backgroundImage.pcImage" :mobile-image="backgroundImage.mobileImage"></page-guide>

    <introduction :productIntroduction="productIntroduction" type="right"></introduction>

    <partner></partner>
    <contact-us></contact-us>
    <m-footer></m-footer>
  </div>
</template>

<script>
import pageGuide from '@/components/page-guide';
import introduction from "@/components/introduction";
import partner from "@/view/content/partner";
import contactUs from "@/components/contact-us";
import footer from "@/components/footer";
import { ProductIntroduction } from "@/assets/js/fixed_data"

export default {
  name: "index",
  components: {
    pageGuide,
    introduction,
    partner,
    contactUs,
    'm-footer': footer
  },
  data() {
    return {
      options: [{
        content: 'Magics',
        animate: 'fadeIn',
        delay: '0s'
      }, {
        content: '数字',
        animate: 'fadeIn',
        delay: '0.8s'
      }, {
        content: '内容',
        animate: 'fadeIn',
        delay: '0.5s'
      }, {
        content: '生产',
        animate: 'fadeIn',
        delay: '0.7s'
      }],
      slogan: '让人人成为虚拟形象的内容生产者',
      backgroundImage: {
        pcImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/bg1.png',
        mobileImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/01A.png'
      },
      productIntroduction: ProductIntroduction.ContentProduction
    }
  }
}
</script>

<style scoped>

</style>
